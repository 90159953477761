<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>Documento - Banca Dati</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-card v-if="doc">
        <ion-card-header color="tertiary">
          <ion-grid>
            <ion-row>
              <ion-col class="ion-text-start">
                <ion-text color="secondary">
                  <b>{{ doc.materia }}</b>
                </ion-text>
              </ion-col>
              <ion-col class="ion-text-end" v-if="doc.dateFormatted">
                <ion-text color="secondary">
                  <b>{{ doc.dateFormatted }}</b>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row size="12">
              <ion-col size="12">
                <ion-text>
                  <h6>
                    <b>{{ doc.title }}</b>
                  </h6>
                </ion-text>
                <ion-card-subtitle class="abstract">
                  <i><div class="abstract" v-html="doc.abstract" /></i>
                </ion-card-subtitle>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content class="text">
          <ion-grid>
            <ion-row v-if="!paywall && (!selected || selected.length == 0)">
              <ion-col size="12">
                <div class="text" v-html="doc.text" />
              </ion-col>
            </ion-row>
            <ion-row v-if="!paywall && selected && selected.length > 0">
              <ion-col size="12" v-for="se in selected" :key="se.id">
                <div class="text" v-html="se.text" />
                <hr class="divider" />
              </ion-col>
            </ion-row>
            <ion-row v-if="paywall">
              <ion-col size="12">
                <div class="text" v-html="doc.shortText1" />
              </ion-col>
            </ion-row>
            <ion-row v-if="paywall">
              <ion-col>
                <ion-card @click="goToLogin" color="secondary">
                  <ion-card-content class="ion-text-center">
                    <div>Accedi per visualizzare il documento</div>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-fab
        v-if="hasStructure && !paywall"
        horizontal="start"
        vertical="bottom"
        slot="fixed"
      >
        <ion-fab-button color="secondary" @click="showTree()">
          <fa icon="sitemap"></fa>
        </ion-fab-button>
      </ion-fab>
      <ion-fab horizontal="end" vertical="bottom" slot="fixed" v-if="!paywall">
        <ion-fab-button color="secondary">
          <ion-icon
            md="caret-back"
            ios="chevron-back-circle-outline"
          ></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="start" v-if="doc">
          <jn-facebook-sharing :doc="doc" />
          <jn-linkedin-sharing :doc="doc" />
          <jn-email-sharing :doc="doc" />
        </ion-fab-list>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  onBeforeMount,
  watch,
} from "vue";
import { useStore } from "vuex";
import {
  document,
  search,
  logoLinkedin,
  logoInstagram,
  logoFacebook,
  mailOutline,
  caretBack,
  chevronBackCircleOutline,
  print,
} from "ionicons/icons";
import { addIcons } from "ionicons";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Categories } from "@/models/enums.model";
import * as eventBus from "../../services/event.bus.js";
import { useRouter, useRoute } from "vue-router";
import { SearchTypes } from "@/models/enums.model";
import { Structure } from "@/models/structure.model";
import { StructureElement } from "@/models/structure.element.model";
import JnPreviewDoc from "../../components/PreviewDoc.vue";
import JnJuranetNavigationStructure from "./JuranetNavigationStructure.vue";
import JnFacebookSharing from "../../components/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "../../components/sharing/LinkedinSharing.vue";
import JnEmailSharing from "../../components/sharing/EmailSharing.vue";
import { useCookie } from "@vue-composable/cookie";
import { useMeta } from "vue-meta";
addIcons({
  document: document,
  search: search,
  "logo-linkedin": logoLinkedin,
  "logo-instagram": logoInstagram,
  "logo-facebook": logoFacebook,
  "mail-outline": mailOutline,
  "caret-back": caretBack,
  "chevron-back-circle-outline": chevronBackCircleOutline,
  print: print,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonFabList,
} from "@ionic/vue";

export default defineComponent({
  name: "JnJuranetDoc",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonCardSubtitle,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonFabList,
    JnFacebookSharing,
    JnLinkedinSharing,
    JnEmailSharing,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paywall = ref(true);

    const loading = ref(true);
    const timeout = ref(5000);

    const doc = ref();
    const id = route.params.id;
    const target = route.params.target;
    const type = route.params.type;
    const structure = ref();
    const selectedKey = ref("");

    const hasStructure = ref(false);
    const selected = ref([] as StructureElement[]);

    function goToHome() {
      location.href = "/main/home";
    }

    watch(doc, async (newDocument: Document, oldDocument: Document) => {
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
        id: String(newDocument.id),
        size: 1,
        from: 0,
        withText: true,
        searchInMassime: false,
      });
      const rawStructure = await EsService.getStructure(searchData);
      if (rawStructure) {
        const jsonStructure = JSON.parse(
          rawStructure.hits.hits[0]._source.structure
        );
        const tags = jsonStructure.tags;
        hasStructure.value = tags.length > 0;
        structure.value = new Structure({
          id: newDocument.id,
          docId: String(newDocument.id),
          name: newDocument.title,
          idCategory: newDocument.idCategory,
          jsonStructure: jsonStructure,
        });
      }
    });

    onMounted(async () => {
      eventBus.default.$on("paywall", function (data: boolean) {
        paywall.value = data;
      });

      const paywallCookie = useCookie("paywall");

      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == "true";
      }

      let searchData = undefined;
      if (route.params.type) {
        searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
          id:
            route.params.id +
            "#" +
            route.params.target +
            "#" +
            route.params.type,
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
      } else {
        searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_INDEXES,
          id: String(route.params.id),
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
      }
      doc.value = await EsService.getDoc(searchData!);
      console.log(doc);
      loading.value = false;
    });

    const showTree = async () => {
      const modal = await modalController.create({
        component: JnJuranetNavigationStructure,
        componentProps: { structure: structure.value },
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        selected.value = (result.data as Structure).getSelected();

        const searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
        for (const se of selected.value) {
          searchData.id = se.id;
          const fragmentDoc = await EsService.getDoc(searchData);
          se.text = fragmentDoc?.text;
        }
      }
    };

    const goToLogin = async () => {
      modalController.dismiss(undefined);
      store.commit("isJuranews", doc.value.isJuranews);
      store.commit("docId", doc.value.id);
      router.push("/login");
    };

    return {
      doc,
      hasStructure,
      showTree,
      paywall,
      goToLogin,
      selected,
      goToHome,
    };
  },
});
</script>
