
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  onBeforeMount,
  watch,
} from "vue";
import { useStore } from "vuex";
import {
  document,
  search,
  logoLinkedin,
  logoInstagram,
  logoFacebook,
  mailOutline,
  caretBack,
  chevronBackCircleOutline,
  print,
} from "ionicons/icons";
import { addIcons } from "ionicons";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";
import { Categories } from "@/models/enums.model";
import * as eventBus from "../../services/event.bus.js";
import { useRouter, useRoute } from "vue-router";
import { SearchTypes } from "@/models/enums.model";
import { Structure } from "@/models/structure.model";
import { StructureElement } from "@/models/structure.element.model";
import JnPreviewDoc from "../../components/PreviewDoc.vue";
import JnJuranetNavigationStructure from "./JuranetNavigationStructure.vue";
import JnFacebookSharing from "../../components/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "../../components/sharing/LinkedinSharing.vue";
import JnEmailSharing from "../../components/sharing/EmailSharing.vue";
import { useCookie } from "@vue-composable/cookie";
import { useMeta } from "vue-meta";
addIcons({
  document: document,
  search: search,
  "logo-linkedin": logoLinkedin,
  "logo-instagram": logoInstagram,
  "logo-facebook": logoFacebook,
  "mail-outline": mailOutline,
  "caret-back": caretBack,
  "chevron-back-circle-outline": chevronBackCircleOutline,
  print: print,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonFabList,
} from "@ionic/vue";

export default defineComponent({
  name: "JnJuranetDoc",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonCard,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonCardSubtitle,
    IonCardHeader,
    IonFab,
    IonFabButton,
    IonFabList,
    JnFacebookSharing,
    JnLinkedinSharing,
    JnEmailSharing,
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paywall = ref(true);

    const loading = ref(true);
    const timeout = ref(5000);

    const doc = ref();
    const id = route.params.id;
    const target = route.params.target;
    const type = route.params.type;
    const structure = ref();
    const selectedKey = ref("");

    const hasStructure = ref(false);
    const selected = ref([] as StructureElement[]);

    function goToHome() {
      location.href = "/main/home";
    }

    watch(doc, async (newDocument: Document, oldDocument: Document) => {
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
        id: String(newDocument.id),
        size: 1,
        from: 0,
        withText: true,
        searchInMassime: false,
      });
      const rawStructure = await EsService.getStructure(searchData);
      if (rawStructure) {
        const jsonStructure = JSON.parse(
          rawStructure.hits.hits[0]._source.structure
        );
        const tags = jsonStructure.tags;
        hasStructure.value = tags.length > 0;
        structure.value = new Structure({
          id: newDocument.id,
          docId: String(newDocument.id),
          name: newDocument.title,
          idCategory: newDocument.idCategory,
          jsonStructure: jsonStructure,
        });
      }
    });

    onMounted(async () => {
      eventBus.default.$on("paywall", function (data: boolean) {
        paywall.value = data;
      });

      const paywallCookie = useCookie("paywall");

      if (!paywallCookie.cookie.value) {
        paywall.value = true;
      } else {
        paywall.value = paywallCookie.cookie.value == "true";
      }

      let searchData = undefined;
      if (route.params.type) {
        searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
          id:
            route.params.id +
            "#" +
            route.params.target +
            "#" +
            route.params.type,
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
      } else {
        searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_INDEXES,
          id: String(route.params.id),
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
      }
      doc.value = await EsService.getDoc(searchData!);
      console.log(doc);
      loading.value = false;
    });

    const showTree = async () => {
      const modal = await modalController.create({
        component: JnJuranetNavigationStructure,
        componentProps: { structure: structure.value },
      });

      modal.present();
      const result = await modal.onDidDismiss();
      if (result.data) {
        selected.value = (result.data as Structure).getSelected();

        const searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES,
          size: 1,
          from: 0,
          withText: true,
          searchInMassime: false,
        });
        for (const se of selected.value) {
          searchData.id = se.id;
          const fragmentDoc = await EsService.getDoc(searchData);
          se.text = fragmentDoc?.text;
        }
      }
    };

    const goToLogin = async () => {
      modalController.dismiss(undefined);
      store.commit("isJuranews", doc.value.isJuranews);
      store.commit("docId", doc.value.id);
      router.push("/login");
    };

    return {
      doc,
      hasStructure,
      showTree,
      paywall,
      goToLogin,
      selected,
      goToHome,
    };
  },
});
