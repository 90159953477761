
import { defineComponent } from 'vue';
import { Document } from '@/models/document.model';
import { IonFabButton, IonIcon } from '@ionic/vue';

export default defineComponent({
  name: 'JnFacebookSharing',
  props: {
    doc: {
      type: Document,
      required: true,
    },
  },
  components: {
    IonFabButton,
    IonIcon,
  },

  setup(props) {
    return {};
  },
});
