<template>
  <ion-fab-button color="secondary">
    <share-network
      network="facebook"
      :url="doc.url"
      :title="doc.title"
      :description="doc.shortAbstract2"
      :quote="doc.title"
      hashtags="juranews"
      class="text-decoration-none"
    >
      <ion-icon name="logo-facebook"></ion-icon>
    </share-network>
  </ion-fab-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Document } from '@/models/document.model';
import { IonFabButton, IonIcon } from '@ionic/vue';

export default defineComponent({
  name: 'JnFacebookSharing',
  props: {
    doc: {
      type: Document,
      required: true,
    },
  },
  components: {
    IonFabButton,
    IonIcon,
  },

  setup(props) {
    return {};
  },
});
</script>
