import { StructureElement } from './structure.element.model';

interface StructureProps {
  // Deve essere number per il TreeView
  idTree?: number;
  id: number;
  docId: string;
  name: string;
  idCategory: string;
  text?: string;
  jsonStructure: any;
}

export class Structure {
  private props: StructureProps;

  private children: StructureElement[] = [];

  // Contatore id dei figli
  private childId = 2;
  // Mappa id ("docId #target#type") all'id numerico del tree
  private idMap = new Map();

  constructor(props: StructureProps) {
    this.props = props;
    this.idTree = 1;
    this.build();
  }

  keyByElement(element: StructureElement) {
    if (element.version) {
      return (
        element.docId +
        '#' +
        element.target +
        '#' +
        element.type +
        '#' +
        element.version
      );
    }
    return element.docId + '#' + element.target + '#' + element.type;
  }

  key(
    docId: string,
    target: string,
    type: string,
    version: string | undefined
  ) {
    if (version) {
      return docId + '#' + target + '#' + type + '#' + version;
    }
    return docId + '#' + target + '#' + type;
  }

  getChild(id: string): StructureElement | undefined {
    return this.getChildImpl(this.children, id);
  }

  private getChildImpl(
    children: StructureElement[],
    id: string
  ): StructureElement | undefined {
    let child: StructureElement | undefined;
    for (const c of children) {
      if (c.id === id) {
        child = c;
      }
      if (!child) {
        child = this.getChildImpl(c.getChildren(), id);
      }
    }
    return child;
  }

  getChildren(): StructureElement[] {
    return this.children;
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }
  
  public getSelected(): StructureElement[]{
    const selectedChildren: StructureElement[] = [];
    for (const child of this.children) {
      if (child.selected) {
        selectedChildren.push(child)
      }

      if (child.hasChildren()) {
        const childSelectedChildren = this.getSelectedChildren(child.getChildren())
        for (const c of childSelectedChildren) {
          selectedChildren.push(c);
        }
      }
    }
    return selectedChildren;
  }

  private getSelectedChildren(children: StructureElement[]): StructureElement[] {
    const selectedChildren: StructureElement[] = [];
    for (const child of children) {
      if (child.selected) {
        selectedChildren.push(child)
      }

      if (child.hasChildren()) {
        const childSelectedChildren = this.getSelectedChildren(child.getChildren())
        for (const c of childSelectedChildren) {
          selectedChildren.push(c);
        }
      }
    }
    return selectedChildren;
  }

  private build() {
    for (const tag of this.props.jsonStructure.tags) {
      const element = new StructureElement({
        idTree: this.childId++,
        id: this.key(this.props.docId, tag.target, tag.type, undefined),
        docId: this.props.docId,
        target: String(tag.target),
        type: String(tag.type),
        name: String(tag.label),
        idCategory: String(this.props.idCategory),
        since: String(tag.data_decorrenza),
        historySize: Number(tag.historySize),
        selected: false,
        parent: undefined
      });
      this.idMap.set(element.id, element.id);

      if (tag.tags) {
        element.addChildren(this.buildChildren(element, tag.tags));
      }
      this.children.push(element);
    }
  }

  private buildChildren(parent: StructureElement, tags: any): StructureElement[] {
    const children: StructureElement[] = [];
    for (const tag of tags) {
      const element = new StructureElement({
        idTree: this.childId++,
        id: this.key(this.props.docId, tag.target, tag.type, undefined),
        docId: this.props.docId,
        target: String(tag.target),
        type: String(tag.type),
        name: String(tag.label),
        idCategory: String(this.props.idCategory),
        since: String(tag.data_decorrenza),
        historySize: Number(tag.historySize),
        version: '0',
        selected: false,
        parent: parent
      });
      this.idMap.set(element.id, element.idTree);

      // Se l'elemento ha più versioni, aggiungi N elementi, uno per ogni versione
      if (element.historySize > 0) {
        const historyElements: StructureElement[] = [];
        for (let i = 0; i < element.historySize; i++) {
          historyElements.push(
            new StructureElement({
              idTree: this.childId++,
              id: this.key(
                this.props.docId,
                tag.target,
                tag.type,
                String(i + 1)
              ),
              docId: this.props.docId,
              target: String(tag.target),
              type: String(tag.type),
              name: 'Versione ' + String(i + 1),
              version: String(i + 1),
              idCategory: String(this.props.idCategory),
              since: String(tag.data_decorrenza),
              historySize: Number(tag.historySize),
              selected: false,
              parent: parent
            })
          );
        }
        element.addChildren(historyElements);
      }

      children.push(element);
      if (tag.tags && element.historySize == 0) {
        element.addChildren(this.buildChildren(element, tag.tags));
      }
    }
    return children;
  }

  get template(): string {
    return (
      '<v-card elevation="4"><v-card-text>' +
      this.props.text +
      '</v-card-text></v-card>'
    );
  }

  // GETTERs and SETTERs
  get idTree(): number | undefined {
    return this.props.idTree;
  }

  set idTree(idTree: number | undefined) {
    this.props.idTree = idTree;
  }

  get id(): number {
    return this.props.id;
  }

  set id(id: number) {
    this.props.id = id;
  }

  get name(): string {
    return this.props.name;
  }

  set name(name: string) {
    this.props.name = name;
  }

  get text(): string | undefined {
    return this.props.text;
  }

  set text(text: string | undefined) {
    if (text) {
      this.props.text = text;
    }
  }
}
