
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  onBeforeMount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import {
  document,
  search,
  logoLinkedin,
  logoInstagram,
  logoFacebook,
  caretBack,
  chevronBackCircleOutline,
  print,
  arrowBack,
} from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import { Categories } from '@/models/enums.model';
import * as eventBus from '../../services/event.bus.js';
import { useRouter, useRoute } from 'vue-router';
import { SearchTypes } from '@/models/enums.model';
import { Structure } from '@/models/structure.model';
import { StructureElement } from '@/models/structure.element.model';
import JnPreviewDoc from '../../components/PreviewDoc.vue';

addIcons({
  document: document,
  search: search,
  'logo-linkedin': logoLinkedin,
  'logo-instagram': logoInstagram,
  'logo-facebook': logoFacebook,
  'caret-back': caretBack,
  'chevron-back-circle-outline': chevronBackCircleOutline,
  'arrow-back': arrowBack,
  print: print,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonFabList,
  IonListHeader,
  IonCheckbox,
} from '@ionic/vue';

export default defineComponent({
  name: 'JnJuranetNavigationStructure',
  props: {
    structure: {
      type: Structure,
      required: true,
    },
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
    IonTitle,
    IonMenuButton,
    IonListHeader,
    IonLabel,
    IonList,
    IonItem,
    IonCheckbox,
    IonFab,
    IonFabButton,
  },

  setup(props) {
    const structureElements = ref([] as StructureElement[]);
    const parent = ref<StructureElement | undefined>();

    const doc = ref();
    onBeforeMount(async () => {
      for (const se of props.structure.getChildren()) {
        structureElements.value.push(se);
      }
    });

    const close = async () => {
      modalController.dismiss(props.structure);
    };

    const goToChildren = async (structureElement: StructureElement) => {
      parent.value = structureElement;
      structureElements.value = [];
      for (const se of structureElement.getChildren()) {
        structureElements.value.push(se);
      }
    };

    const goToParent = async () => {
      structureElements.value = [];
      for (const se of props.structure.getChildren()) {
        structureElements.value.push(se);
        parent.value = se.getParent();
      }
    };

    return { doc, close, structureElements, goToChildren, goToParent, parent };
  },
});
